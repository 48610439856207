import React from "react"
import "../Echipamente/main.styles.scss"
import "./list.styles.scss"
import { graphql, useStaticQuery } from "gatsby"
import Project from "./Project"
import { useIntl } from "gatsby-plugin-intl"

const getImages = graphql`
{
  allFile(filter:{relativeDirectory:{eq:"Projects"}})
  {
      edges{
          node {
              childImageSharp{
                  fluid(maxHeight: 370, quality: 100){
                      ...GatsbyImageSharpFluid
                      originalName
                  }
              }
          }
      }
  }
}     
`
export const List = () => {

    const { allFile } = useStaticQuery(getImages);
    const getImageByName = (name) => {
        let image = null
        image = allFile.edges.find(file => file.node.childImageSharp.fluid.originalName === name)
        return image
    }

    const intl = useIntl()

    return (
        <div className="projects_list">
            <h1>{intl.formatMessage({ id: "projectsTitle" })}</h1>
            <div className="echipamente_list" itemScope itemType="https://schema.org/ItemList">
                <Project
                    image={getImageByName('hero15.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-de-caldura-idm-ipump-t-3-13-inverter.title" })}
                    redirectUrl="pompa-de-caldura-idm-ipump-t-3-13-inverter"
                    alt="pompa de caldura idm ipump t 3-13 inverter"
                />
                <Project
                    image={getImageByName('hero5.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-de-caldura-sol-apa-idm-ipump-3-13p.title" })}
                    redirectUrl="pompa-de-caldura-sol-apa-idm-ipump-3-13p"
                    alt="pompa de caldura sol-apa idm ipump 3-13p"
                />
                <Project
                    image={getImageByName('hero8.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-de-caldura-aer-apa-ipump-a.title" })}
                    redirectUrl="pompa-de-caldura-aer-apa-ipump-a"
                    alt="pompa de caldura aer-apa ipump a"
                />
                <Project
                    image={getImageByName('hero9.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-sol-apa-ipump-t.title" })}
                    redirectUrl="pompa-sol-apa-ipump-t"
                    alt="pompa sol-apa ipump t"
                />
                <Project
                    image={getImageByName('hero12.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-idm-ipump-t-2-8-kw-sol-apa.title" })}
                    redirectUrl="pompa-idm-ipump-t-2-8-kw-sol-apa"
                    alt="pompa idm ipump t 2-8kw sol-apa"
                />
                <Project
                    image={getImageByName('hero13.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-sol-apa.title" })}
                    redirectUrl="pompa-sol-apa"
                    alt="pompa sol-apa"
                />
                <Project
                    image={getImageByName('hero14.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-de-caldura-sol-apa-idm-slm-6-17-kw.title" })}
                    redirectUrl="pompa-de-caldura-sol-apa-idm-slm-6-17-kw"
                    alt="pompa de caldura sol-apa idm slm 6-17kw"
                />
                <Project
                    image={getImageByName('hero4.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.pompa-de-caldura-sol-apa-ipump-t-3-13kw.title" })}
                    redirectUrl="pompa-de-caldura-sol-apa-ipump-t-3-13kw"
                    alt="pompa de caldura sol-apa ipump t 3-13kw"
                />
                <Project
                    image={getImageByName('hero6.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.idm-terra-sw-27kw.title" })}
                    redirectUrl="idm-terra-sw-27kw"
                    alt="idm terra sw 27kw"
                />
                <Project
                    image={getImageByName('hero1.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.instalatii-sanitare-si-incalzire-in-pardoseala.title" })}
                    redirectUrl="instalatii-sanitare-si-incalzire-in-pardoseala"
                    alt="instalatii sanitare si incalzire in pardoseala"
                />
                <Project
                    image={getImageByName('hero2.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.sistem-de-ventilatie-cu-recuperare-de-caldura.title" })}
                    redirectUrl="sistem-de-ventilatie-cu-recuperare-de-caldura"
                    alt="sistem de ventilatie cu recuperare de caldura"
                />
                <Project
                    image={getImageByName('hero3.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.sistem-de-ventilatie-centralizat-cu-recuperare-de-caldura.title" })}
                    redirectUrl="sistem-de-ventilatie-centralizat-cu-recuperare-de-caldura"
                    alt="sistem de ventilatie centralizat cu recuperare de caldura"
                />
                <Project
                    image={getImageByName('hero11.webp').node.childImageSharp.fluid}
                    name={intl.formatMessage({ id: "projects.bai.title" })}
                    redirectUrl="bai"
                    alt="bai"
                />
            </div>
        </div>

    )
}
