import React from "react"
import Image from "gatsby-image"
import "../Echipamente/main.styles.scss"
import "./list.styles.scss"
import { useIntl, Link } from "gatsby-plugin-intl"

const Project = ({ name, image, redirectUrl, alt }) => {

    const intl = useIntl()

    return (
        <div className="echipamente_list-item shadow-effect">
            <Link style={{ textDecoration: "none" }} to={"/proiecte/" + redirectUrl}>
                <div className="echipamente_list_container">
                    <span className="echipamente_list_secondary"></span>
                    <div className="image_content">
                        <Image itemProp="image" fluid={image} className="projects_gatsby_image" alt={alt} />
                        <div className="proiecte_list_content">
                            <hr />
                            <h2 style={{ height: "30px" }} itemProp="name">{name}</h2>
                        </div>
                    </div>
                    <Link to={"/proiecte/" + redirectUrl} style={{ textDecoration: "none", color: "black" }} className={"echipamente_list-item_details"}>
                        <div>
                            {intl.formatMessage({ id: "equipmentDetails" })}
                        </div>
                    </Link>
                </div>
            </Link>
        </div>
    )
}

export default Project
