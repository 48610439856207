import React from "react"
import Layout from "../components/Layout/Layout"
import { StyledHeader } from "../components/Header/Header"
import { List } from "../components/Proiecte/List"
import { IDM } from "../components/Echipamente/IDM"
import SEO from "../components/SEO/SEO"

import { graphql } from "gatsby"
import TagManager from "../components/TagManager/TagManager"

export default ({ data }) => {
  return (
    <Layout>
      <TagManager />
      <SEO
        title="Proiecte"
        description="G4H este specializata in instalarea pompelor de caldura create la comanda pentru fiecare client. "
        robots="index, follow"
        canonical="https://g4h.ro/proiecte"
        openGraph={{ url: "https://www.g4h.ro/proiecte", type: "website", image: "https://g4h.ro/static/4d4bdc87299d018a36e6df80d3d2a043/2965c/hero1.jpg", title: "Proiecte", description: "G4H este specializata in instalarea pompelor de caldura create la comanda pentru fiecare client." }}
      />
      <StyledHeader
        echipamente
        image={data.contactheader.childImageSharp.fluid}
      >
        <IDM isProject={true} />
      </StyledHeader>
      <List />
    </Layout>
  )
}
export const query = graphql`
  {
    contactheader: file(relativePath: { eq: "fundal.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
