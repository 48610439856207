import React from "react"
import "./idm.styles.scss"

export const IDM = ({isProject}) => {
  return (
    <div className={isProject ? "echipamente_idm border" : "echipamente_idm"}>
      <div className="echipamente_idm_center">
        <div className="echipamente_idm_content">
        </div>
      </div>
    </div>
  )
}
